import { useEffect, useState } from "react";
import { Link as RouterLink } from "react-router-dom";
import Link from "@mui/material/Link";
// import makeStyles from "@mui/styles/makeStyles";
import { styled } from "@mui/material/styles";
import Box from "@mui/material/Box";
import Snackbar from "@mui/material/Snackbar";
import Alert from "@mui/material/Alert";
import Container from "@mui/material/Container";
import Typography from "@mui/material/Typography";
import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Avatar from "@mui/material/Avatar";
import Chip from "@mui/material/Chip";
import AvatarGroup from "@mui/material/AvatarGroup";

import { baseApiUrl } from "../config";
import firebase from "firebase/app";
import "firebase/auth";

import confluenceData from "./confluence.json";
import appData from "./app.json";
import bigqueryData from "./bigquery.json";

// const useStyles = makeStyles((theme) => ({
// }));


const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    fontWeight: "bold",
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  '&:nth-of-type(odd)': {
    backgroundColor: theme.palette.action.hover,
  },
  // hide last border
  '&:last-child td, &:last-child th': {
    border: 0,
  },
}));

export default function ModulesView() {
  // const classes = useStyles();
  const [alertOpen, setAlertOpen] = useState(false);
  const [alertMessage, setAlertMessage] = useState("");
  const [modules, setModules] = useState(null);

  useEffect(() => {
    (async () => {
      try {
        const token = await firebase.auth().currentUser.getIdToken();
        const response = await fetch(`${baseApiUrl}/modules`, {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            "Authorization": `Bearer ${token}`,
          },
        });
        const res = await response.json();
        const modules = res.data;
        setModules(modules);
      } catch (err) {
        setAlertMessage(err.toString());
        setAlertOpen(true);
      }
    })();
  }, []);

  const handleAlertClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }

    setAlertOpen(false);
  };

  let rows = [];

  // 1. Start with Confluence as source of truth.
  for (const d of confluenceData) {
    const r = {
      eventName: d.eventName,
      sources: [d.source],
      properties: [],
      appVersions: [],
      figma: "https://www.figma.com/file/uyOu2D2a9Ty9HMFkDbpASR/Design-%2F-app-0.2",
      comments: d.Comments,
      tracks: d.tracks,
      motivation: d.Motivation,
    }
    rows.push(r);
  }

  // 2. Then augment or add App implementation.  
  for (const d of appData) {
    const r = {
      eventName: d.eventName,
      sources: [d.source],
      properties: [],
      appVersions: [],
      figma: "https://www.figma.com/file/uyOu2D2a9Ty9HMFkDbpASR/Design-%2F-app-0.2",
    }

    const i = rows.findIndex(r => r.eventName === d.eventName);
    if (i > -1) {
      rows[i].sources.push(d.source);
    } else {
      rows.push(r);
    }
  }

  // 3. Then augment or add BigQuery events.  
  for (const d of bigqueryData) {
    const r = {
      eventName: d.eventName,
      sources: [d.source],
      properties: [],
      appVersions: [d.version],
      figma: "https://www.figma.com/file/uyOu2D2a9Ty9HMFkDbpASR/Design-%2F-app-0.2",
    }

    const i = rows.findIndex(r => r.eventName === d.eventName);
    if (i > -1) {
      if (rows[i].sources.indexOf(d.source) === -1) {
        rows[i].sources.push(d.source);
      }
      rows[i].appVersions.push(d.version);
    } else {
      rows.push(r);
    }
  }

  // 4. Clean up
  rows = rows.map(r => {
    r.appVersions.sort();
    r.appVersions = r.appVersions.reverse();
    return r;
  });

  rows.sort((a, b) => a.eventName < b.eventName ? -1 : 1);

  return (
    <Container>
      <Snackbar
        open={alertOpen}
        autoHideDuration={6000}
        onClose={handleAlertClose}
      >
        <Alert
          onClose={handleAlertClose}
          severity="error"
          elevation={6}
          variant="filled"
        >
          {alertMessage}
        </Alert>
      </Snackbar>
      <Box m={2}>
      <Paper sx={{ width: "100%", overflow: "hidden" }}>
        <TableContainer>
          <Table stickyHeader size="small">
            <TableHead>
              <TableRow>
                <StyledTableCell component="th">Event name</StyledTableCell>
                <StyledTableCell component="th" align="center">Source(s)</StyledTableCell>
                <StyledTableCell component="th" align="left">Properties</StyledTableCell>
                <StyledTableCell component="th" align="left">App version(s)</StyledTableCell>
                <StyledTableCell component="th" align="center">Figma</StyledTableCell>
                <StyledTableCell component="th" align="left">Tracks</StyledTableCell>
                <StyledTableCell component="th" align="left">Comments</StyledTableCell>
                <StyledTableCell component="th" align="left">Motivation</StyledTableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {rows.map((row) => (
                <StyledTableRow
                  key={row.name}
                  sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                >
                  <StyledTableCell>
                    {row.eventName}
                  </StyledTableCell>
                  <StyledTableCell align="center">
                    <AvatarGroup total={row.sources.length}>
                    {row.sources.map((s) => {
                      let src = "";
                      let color = "";
                      let letter = "";

                      if (s === "confluence") {
                        // src = "https://gracehealth.atlassian.net/wiki/s/1681968937/6452/ffb6b876c7b155562f603ee36dbd7711d13e6d24/6/_/favicon-update.ico";
                        color = "#2279EE";
                        letter = "C";
                      } else if (s === "app") {
                        // src = "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSuz3pWRO2TCGUUiFv8uoqaEAl-dUEabUvdTn-qqtEEoeMHRuHZ0lBUmMSw0HBEvwU23go&usqp=CAU";
                        color = "#4FE190";
                        letter = "A";
                      } else if (s === "bigquery") {
                        // src = "https://symbols.getvecta.com/stencil_75/69_bigquery-icon.0925fbc87f.png";
                        color = "#2279EE";
                        letter = "B";
                      }
                      return <Avatar
                        alt={s}
                        sx={{ bgcolor: color, width: 24, height: 24 }}
                        src={src}>
                        {letter}
                      </Avatar>
                    })}
                    </AvatarGroup>
                  </StyledTableCell>
                  <StyledTableCell align="left">
                    {row.properties}
                  </StyledTableCell>
                  <StyledTableCell align="left" style={{ width: 200 }}>
                    <Typography noWrap>
                      {row.appVersions.slice(0, 5).map((a) => {
                        return <Chip label={a} size="small" variant="outlined" />
                      })}
                    </Typography>
                  </StyledTableCell>
                  <StyledTableCell align="center">
                    <Link
                      component={RouterLink}
                      color="inherit"
                      variant="inherit"
                      underline="hover"
                      to={{pathname: row.figma}}
                      target="_blank">
                      <Avatar
                        alt="Figma"
                        sx={{ width: 16, height: 16 }}
                        src="https://static.figma.com/app/icon/1/favicon.png" />
                    </Link>
                  </StyledTableCell>
                  <StyledTableCell align="left">
                    {row.tracks}
                  </StyledTableCell>
                  <StyledTableCell align="left">
                    {row.comments}
                  </StyledTableCell>
                  <StyledTableCell align="left">
                    {row.motivation}
                  </StyledTableCell>
                </StyledTableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </Paper>
      </Box>
      
    </Container>
  );
}
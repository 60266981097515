import { useEffect, useState } from "react";
import { Link as RouterLink } from "react-router-dom";
// import makeStyles from "@mui/styles/makeStyles";
import Box from "@mui/material/Box";
import Snackbar from "@mui/material/Snackbar";
import Alert from "@mui/material/Alert";
import Container from "@mui/material/Container";
import Masonry from "@mui/lab/Masonry";
import Card from "@mui/material/Card";
import Link from "@mui/material/Link";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import CardContent from "@mui/material/CardContent";
import Typography from "@mui/material/Typography";

import { baseApiUrl } from "../config";
import firebase from "firebase/app";
import "firebase/auth";

// const useStyles = makeStyles((theme) => ({
// }));

export default function ModulesView() {
  // const classes = useStyles();
  const [alertOpen, setAlertOpen] = useState(false);
  const [alertMessage, setAlertMessage] = useState("");
  const [modules, setModules] = useState(null);

  useEffect(() => {
    (async () => {
      try {
        const token = await firebase.auth().currentUser.getIdToken();
        const response = await fetch(`${baseApiUrl}/modules`, {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            "Authorization": `Bearer ${token}`,
          },
        });
        const res = await response.json();
        const modules = res.data;
        setModules(modules);
      } catch (err) {
        setAlertMessage(err.toString());
        setAlertOpen(true);
      }
    })();
  }, []);

  const handleAlertClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }

    setAlertOpen(false);
  };

  return (
    <Container>
      <Snackbar
        open={alertOpen}
        autoHideDuration={6000}
        onClose={handleAlertClose}
      >
        <Alert
          onClose={handleAlertClose}
          severity="error"
          elevation={6}
          variant="filled"
        >
          {alertMessage}
        </Alert>
      </Snackbar>
      <Box m={2}>
        <Masonry spacing={{ xs: 2, md: 4 }} columns={{ xs: 1, sm: 2, md: 4, lg: 6, xl: 8}}>
          {modules === null ? [] : modules.map((c, i) => (
            <Card sx={{ minWidth: 280 }} key={i}>
              <CardContent>
                <Typography color="text.secondary">
                  {c.id}
                </Typography>
                <Typography variant="h6">
                  {c.name}
                </Typography>
                <Typography variant="body2">
                  {c.description}
                </Typography>
                <List dense>
                  {c.intents.map((i, idx) =>
                    <ListItem key={idx}>
                      <Link
                        component={RouterLink}
                        color="inherit"
                        variant="inherit"
                        underline="hover"
                        to={`/intents/${i.id}`}>
                        <Typography>{i.name}</Typography>
                      </Link>
                    </ListItem>
                  )}
                </List>
              </CardContent>
            </Card>
          ))}
        </Masonry>
      </Box>
    </Container>
  );
}
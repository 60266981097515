import { Fragment, useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import TextField from "@mui/material/TextField";
import Autocomplete from "@mui/material/Autocomplete";
import Box from "@mui/material/Box";
import Snackbar from "@mui/material/Snackbar";
import Alert from "@mui/material/Alert";
import CircularProgress from "@mui/material/CircularProgress";
import { baseApiUrl } from "../config";
import firebase from "firebase/app";
import "firebase/auth";

const typeToSymbol = (t) => {
  switch (t) {
    case "intent": return "I";
    case "event": return "E";
    case "trainingPhrase": return "T";
    case "message": return "M";
    default: return t;
  }
}

export default function SearchBar() {
  let history = useHistory();
  const [alertOpen, setAlertOpen] = useState(false);
  const [alertMessage, setAlertMessage] = useState("");
  const [open, setOpen] = useState(false);
  const [options, setOptions] = useState([]);
  const loading = open && options.length === 0;

  useEffect(() => {
    let active = true;

    if (!loading) {
      return undefined;
    }

    (async () => {
      try {
        const token = await firebase.auth().currentUser.getIdToken();
        const response = await fetch(`${baseApiUrl}/intents`, {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            "Authorization": `Bearer ${token}`,
          },
        });
        if (!response.ok) {
          const res = await response.json();
          throw new Error(res.error.details);
        }
        const res = await response.json();
        const intents = res.data;

        if (active) {
          setOptions(intents);
        }
      } catch (err) {
        setAlertMessage(err.toString());
        setAlertOpen(true);
      }
    })();

    return () => {
      active = false;
    };
  }, [loading]);

  useEffect(() => {
    if (!open) {
      setOptions([]);
    }
  }, [open]);

  const handleOnChange = (event, value, reason) => {
    event.preventDefault();

    switch (reason) {
      case "selectOption":
        history.push(`/intents/${value.id}`);
        break;
      case "clear":
        history.push("/intents");
        break;
      default:
        return;
    }
  }

  const handleAlertClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }

    setAlertOpen(false);
  };

  return (
    <>
      <Snackbar
        open={alertOpen}
        autoHideDuration={6000}
        onClose={handleAlertClose}
      >
        <Alert
          onClose={handleAlertClose}
          severity="error"
          elevation={6}
          variant="filled"
        >
          {alertMessage}
        </Alert>
      </Snackbar>
      <Autocomplete
      id="searchbar"
      open={open}
      onOpen={() => setOpen(true)}
      onClose={() => setOpen(false)}
      onChange={handleOnChange}
      isOptionEqualToValue={(option, value) => option.key === value.key}
      getOptionLabel={(option) => `${typeToSymbol(option.type)} ${option.value}`}
      renderOption={(props, option) => {
        const optionLabel = `${typeToSymbol(option.type)} ${option.value}`;
        return (
          <Box component="li" key={option.key} {...props}>
            {optionLabel}
          </Box>
        );
      }}
      options={options}
      loading={loading}
      groupBy={(option) => option.name}
      renderInput={(params) => (
        <TextField
          {...params}
          fullWidth
          label="Intents"
          variant="outlined"
          margin="normal"
          InputProps={{
            ...params.InputProps,
            endAdornment: (
              <Fragment>
                {loading ? <CircularProgress color="inherit" size={20} /> : null}
                {params.InputProps.endAdornment}
              </Fragment>
            ),
          }}
        />
      )}
    />
   </>
  );
}
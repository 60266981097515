import { Link } from "react-router-dom";
import Drawer from "@mui/material/Drawer";
import Toolbar from "@mui/material/Toolbar";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import ListIcon from "@mui/icons-material/List";
import LocalOfferIcon from "@mui/icons-material/LocalOffer";
import AirlineStopsIcon from "@mui/icons-material/AirlineStops";

export default function DrawerFixed() {
    return (
      <Drawer
        variant="permanent"
        open
      >
        <Toolbar />
        <List>
          <ListItem button component={Link} to="/intents">
            <ListItemIcon>
              <ListIcon />
            </ListItemIcon>
            <ListItemText primary="Intents" />
          </ListItem>
          <ListItem button component={Link} to="/modules">
            <ListItemIcon>
              <LocalOfferIcon />
            </ListItemIcon>
            <ListItemText primary="Modules" />
          </ListItem>
          <ListItem button component={Link} to="/events">
            <ListItemIcon>
              <AirlineStopsIcon />
            </ListItemIcon>
            <ListItemText primary="Events" />
          </ListItem>
        </List>
      </Drawer>
    );
}

import { struct } from "pb-util";

export default function parseMessage(message) {
  switch (message.message) {
    case "card": return JSON.stringify(message.card, null, 2);
    case "image": return JSON.stringify(message.image, null, 2);
    case "payload": return JSON.stringify(struct.decode(message.payload), null, 2);
    case "quickReplies": return JSON.stringify(message.quickReplies, null, 2);
    case "text": return message.text.text.join("\n");
    default: return JSON.stringify(message, null, 2);
  }
}
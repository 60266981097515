import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import makeStyles from "@mui/styles/makeStyles";
import TextField from "@mui/material/TextField";
import Typography from "@mui/material/Typography";
import Grid from "@mui/material/Grid";
import Paper from "@mui/material/Paper";
import Button from "@mui/material/Button";
import Chip from "@mui/material/Chip";
import Avatar from "@mui/material/Avatar";
import FormatQuote from "@mui/icons-material/FormatQuote";
import InputAdornment from "@mui/material/InputAdornment";
import FormControlLabel from "@mui/material/FormControlLabel";
import Switch from "@mui/material/Switch";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import Box from "@mui/material/Box";
import Snackbar from "@mui/material/Snackbar";
import { DataGrid } from "@mui/x-data-grid";
import Alert from "@mui/material/Alert";
import EditIcon from "@mui/icons-material/Edit";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { baseApiUrl } from "../config";
import parseMessage from "../messageParser";
import firebase from "firebase/app";
import "firebase/auth";


const useStyles = makeStyles((theme) => ({
  paper: {
    padding: 12,
  },
  root: {
    flexGrow: 1,
    "& .gridHead": {
      backgroundColor: "#eeeeee",
    },
  },
}));

export default function IntentForm() {
  const classes = useStyles();
  const { id } = useParams();
  const [alertOpen, setAlertOpen] = useState(false);
  const [alertMessage, setAlertMessage] = useState("");
  const [intent, setIntent] = useState(null);

  useEffect(() => {
    (async () => {
      try {
        const token = await firebase.auth().currentUser.getIdToken();
        const response = await fetch(`${baseApiUrl}/intents/${id}`, {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            "Authorization": `Bearer ${token}`,
          },
        });
        if (!response.ok) {
          const res = await response.json();
          throw new Error(res.error.details);
        }
        const res = await response.json();
        const intent = res.data;
        setIntent(intent);
      } catch (err) {
        setAlertMessage(err.message);
        setAlertOpen(true);
      }
    })();
  }, [id]);

  const handleAlertClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }

    setAlertOpen(false);
  };

  return (
    <>
      <Snackbar
        open={alertOpen}
        autoHideDuration={6000}
        onClose={handleAlertClose}
      >
        <Alert
          onClose={handleAlertClose}
          severity="error"
          elevation={6}
          variant="filled"
        >
          {alertMessage}
        </Alert>
      </Snackbar>
      {intent === null ? "" :
        <Paper className={classes.paper}>
          <form noValidate autoComplete="off">

            <Accordion defaultExpanded>
              <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                <Typography variant="h5">General</Typography>
              </AccordionSummary>
              <AccordionDetails>
                <Grid container className={classes.root} spacing={2}>
                  <Grid item xs={12}>
                    <TextField
                      id="intentName"
                      fullWidth
                      disabled
                      label="Id"
                      variant="outlined"
                      value={intent.name}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <TextField
                      id="intentDisplayName"
                      fullWidth
                      disabled
                      label="Name"
                      variant="outlined"
                      value={intent.displayName}
                    />
                  </Grid>
                </Grid>
              </AccordionDetails>
            </Accordion>

            <Accordion defaultExpanded>
              <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                <Typography variant="h5">Contexts</Typography>
              </AccordionSummary>
              <AccordionDetails>
                <Grid container className={classes.root} spacing={2}>
                  <Grid item xs={12}>
                    <TextField
                      id="intentInputContextNames"
                      fullWidth
                      disabled
                      label="Input Contexts"
                      variant="outlined"
                      InputLabelProps={{ shrink: true }}
                      InputProps={{
                        startAdornment: intent.inputContextNames.map((c, i) =>
                          <Chip label={c} key={i} />
                        )
                      }}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <TextField
                      id="intentOutputContexts"
                      fullWidth
                      disabled
                      label="Output Contexts"
                      variant="outlined"
                      InputLabelProps={{ shrink: true }}
                      InputProps={{
                        startAdornment: intent.outputContexts.map((c, i) =>
                          <Chip avatar={<Avatar>{c.lifespanCount}</Avatar>} label={c.name} key={i} />
                        )
                      }}
                    />
                  </Grid>
                </Grid>
              </AccordionDetails>
            </Accordion>

            <Accordion defaultExpanded>
              <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                <Typography variant="h5">Events</Typography>
              </AccordionSummary>
              <AccordionDetails>
                <Grid container className={classes.root} spacing={2}>
                  <Grid item xs={12}>
                    <TextField
                      id="intentEvents"
                      fullWidth
                      disabled
                      label="Events"
                      variant="outlined"
                      InputLabelProps={{ shrink: true }}
                      InputProps={{
                        startAdornment: intent.events.map((e, i) =>
                          <Chip label={e} key={i} />
                        )
                      }}
                    />
                  </Grid>
                </Grid>
              </AccordionDetails>
            </Accordion>

            <Accordion defaultExpanded>
              <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                <Typography variant="h5">Training phrases</Typography>
              </AccordionSummary>
              <AccordionDetails>
                <Grid container className={classes.root} spacing={2}>
                  <div style={{ display: "flex", height: "100%" }}>
                    <div style={{ flexGrow: 1 }}>
                      <DataGrid
                        id="intentTrainingPhrases"
                        autoHeight={true}
                        disableColumnMenu={true}
                        disableColumnSelector={true}
                        disableColumnReorder={true}
                        disableDensitySelector={true}
                        disableExtendRowFullWidth={true}
                        disableSelectionOnClick={true}
                        isCellEditable={() => false}
                        isRowSelectable={() => false}
                        // pageSize={10}
                        columns={[
                          {
                            field: "phrase",
                            headerName: "Phrase",
                            flex: 1,
                            renderCell: (params) => {
                              return (
                                <TextField
                                  fullWidth
                                  disabled
                                  variant="outlined"
                                  InputLabelProps={{ shrink: true }}
                                  InputProps={{
                                    startAdornment: (
                                      <InputAdornment position="start">
                                        <FormatQuote />
                                      </InputAdornment>
                                    ),
                                  }}
                                  value={params.value}
                                />
                              )
                            }
                          }
                        ]}
                        rows={intent.trainingPhrases.map((t, i) => {
                          return {
                            id: t.name,
                            phrase: t.parts.map(p => p.text).join(" "),
                          }
                        })}
                      />
                    </div>
                  </div>
                </Grid>
              </AccordionDetails>
            </Accordion>

            <Accordion defaultExpanded>
              <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                <Typography variant="h5">Action and parameters</Typography>
              </AccordionSummary>
              <AccordionDetails>
                <Grid container className={classes.root} spacing={2}>
                  <Grid item xs={12}>
                    <TextField
                      id="intentAction"
                      fullWidth
                      disabled
                      label="Action"
                      variant="outlined"
                      InputLabelProps={{ shrink: true }}
                      value={intent.action ? intent.action : ""}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <div style={{ display: "flex", height: "100%" }}>
                      <div style={{ flexGrow: 1 }}>
                        <DataGrid
                          id="intentParameters"
                          autoHeight={true}
                          disableColumnMenu={true}
                          disableColumnSelector={true}
                          disableColumnReorder={true}
                          disableDensitySelector={true}
                          disableExtendRowFullWidth={true}
                          disableSelectionOnClick={true}
                          isCellEditable={() => false}
                          isRowSelectable={() => false}

                          columns={[
                            {
                              field: "required",
                              headerName: "Required",
                              headerClassName: "gridHead",
                              flex: 1,
                              type: "boolean"
                            },
                            {
                              field: "parameterName",
                              headerName: "Parameter Name",
                              headerClassName: "gridHead",
                              flex: 1,
                            },
                            {
                              field: "entity",
                              headerName: "Entity",
                              headerClassName: "gridHead",
                              flex: 1,
                            },
                            {
                              field: "value",
                              headerName: "Value",
                              headerClassName: "gridHead",
                              flex: 1,
                            },
                            {
                              field: "isList",
                              headerName: "Is List",
                              headerClassName: "gridHead",
                              flex: 1,
                              type: "boolean"
                            },

                          ]}
                          rows={intent.parameters.map((p, i) => {
                            return {
                              id: p.name,
                              required: p.mandatory,
                              parameterName: p.displayName,
                              entity: "",
                              value: p.value,
                              isList: p.isList,
                            }
                          })}
                        />
                      </div>
                    </div>
                  </Grid>
                </Grid>
              </AccordionDetails>
            </Accordion>

            <Accordion defaultExpanded>
              <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                <Typography variant="h5">Responses</Typography>
              </AccordionSummary>
              <AccordionDetails>
                <Grid container className={classes.root} spacing={2}>
                  {intent.messages.map((m, i) => {
                    const message = parseMessage(m);
                    return <Grid item xs={12} key={i}>
                      <TextField
                        id={`intentMessage${i}`}
                        fullWidth
                        disabled
                        multiline
                        // rows={m.length}
                        variant="outlined"
                        InputLabelProps={{ shrink: true }}
                        value={message}
                      />
                    </Grid>
                  })}
                </Grid>
              </AccordionDetails>
            </Accordion>

            <Accordion defaultExpanded>
              <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                <Typography variant="h5">Fulfillment</Typography>
              </AccordionSummary>
              <AccordionDetails>
                <Grid container className={classes.root} spacing={2}>
                  <Grid item xs={12}>
                    <FormControlLabel
                      control={<Switch
                        id="intentWebhookState"
                        disabled
                        checked={intent.webhookState === "WEBHOOK_STATE_ENABLED"}
                      />}
                      label="Webhook"
                    />
                  </Grid>
                </Grid>
              </AccordionDetails>
            </Accordion>

            <Box m={2}>
              <Grid container className={classes.root} spacing={2}>
                <Grid item xs={12}>
                  <Button
                    variant="contained"
                    color="secondary"
                    aria-label="edit">
                    <EditIcon /> Edit
                  </Button>
                </Grid>
              </Grid>
            </Box>
          </form>
        </Paper >
      }
    </>
  );
}

import React from "react";
import Button from "@mui/material/Button";
import Avatar from "@mui/material/Avatar";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import { FirebaseAuthConsumer } from "@react-firebase/auth";

export default function ProfileButton() {
  const [anchorEl, setAnchorEl] = React.useState(null);

  const handleMenu = (event) => setAnchorEl(event.currentTarget);
  const handleClose = () => setAnchorEl(null);
  const handleLogout = (firebase) => { firebase.app().auth().signOut(); }

  return (
    <FirebaseAuthConsumer>
      {({ user, firebase }) => {
        return (
          <div>
            <Button
              endIcon={
                <Avatar
                  alt={user.displayName}
                  src={user.photoURL}
                />
              }
              onClick={handleMenu}>
              {user.displayName}
            </Button>
            <Menu
              anchorEl={anchorEl}
              keepMounted
              open={Boolean(anchorEl)}
              onClose={handleClose}
            >
              <MenuItem onClick={handleClose} disabled>{user.email}</MenuItem>
              <MenuItem onClick={() => handleLogout(firebase)}>Logout</MenuItem>
            </Menu>
          </div>
        );
      }}
    </FirebaseAuthConsumer>
  );
}
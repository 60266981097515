import React from "react";
import makeStyles from "@mui/styles/makeStyles";
import Box from "@mui/material/Box";
import Container from "@mui/material/Container";
import Fab from "@mui/material/Fab";
import Tooltip from "@mui/material/Tooltip";
import AddIcon from "@mui/icons-material/Add";
import SearchBar from "./SearchBar";
import IntentForm from "./IntentForm";

const useStyles = makeStyles((theme) => ({
  fab: {
    position: "fixed",
    bottom: theme.spacing(2),
    right: theme.spacing(2),
  },
}));

export default function IntentView() {
  const classes = useStyles();

  return (
    <Container>
      <Box m={2}>
        <SearchBar />
      </Box>
      <Box m={2}>
        <IntentForm />
      </Box>

      <Tooltip title="Add" aria-label="add">
        <Fab color="primary" className={classes.fab} aria-label="add">
          <AddIcon />
        </Fab>
      </Tooltip>
    </Container>
  );
}
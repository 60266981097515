import React from "react";
import { BrowserRouter, Switch, Route, Redirect } from "react-router-dom";
import { ThemeProvider, StyledEngineProvider, createTheme } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import { FirebaseAuthProvider, IfFirebaseAuthed, IfFirebaseUnAuthed } from "@react-firebase/auth";
import firebase from "firebase/app";
import "firebase/auth";
import { firebaseConfig } from "../config";
import AppTopBar from "./AppTopBar";
import DrawerFixed from "./Drawer";
import IntentView from "./IntentView";
import IntentSearchView from "./IntentSearchView";
import ModulesView from "./ModulesView";
import EventsView from "./EventsView";


const graceTheme = createTheme({
  // blue: #2f3343
  // orange: #e39580
  // beige: #ead5cc
  palette: {
    primary: {
      main: "#e39580",
      contrastText: "#fff",
    },
    secondary: {
      main: "#ead5cc",
      contrastText: "#000",
    },
  },
  appBar: {
    height: 60,
  },
});

const useStyles = makeStyles((theme) => ({
  drawerOffset: {
    marginLeft: "150px",
  }
}));

export default function App() {
  const handleLogin = (firebase) => {
    const googleAuthProvider = new firebase.auth.GoogleAuthProvider();
    firebase.auth().signInWithPopup(googleAuthProvider);
  }

  const classes = useStyles();

  return (
    <FirebaseAuthProvider {...firebaseConfig} firebase={firebase}>
      <IfFirebaseAuthed>
        {() => (
          <StyledEngineProvider injectFirst>
            <ThemeProvider theme={graceTheme}>
              <BrowserRouter>
                <AppTopBar />
                <DrawerFixed />
                <div className={classes.drawerOffset}>
                  <Switch>
                    <Redirect from="/" exact to="/intents" />
                    <Route path="/intents" exact component={IntentSearchView} />
                    <Route path="/intents/:id" component={IntentView} />
                    <Route path="/modules" component={ModulesView} />
                    <Route path="/events" component={EventsView} />
                  </Switch>
                </div>
              </BrowserRouter>
            </ThemeProvider>
          </StyledEngineProvider>
        )}
      </IfFirebaseAuthed>
      <IfFirebaseUnAuthed>
        {({ firebase }) => (
          <button onClick={() => handleLogin(firebase)}>Sign in</button>
        )}
      </IfFirebaseUnAuthed>
    </FirebaseAuthProvider>
  );
}
